import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <h2>
        check it here :
        <a href='https://st-sih-1134.azurewebsites.net/'> Click_here</a>
      </h2>
    </div>
  );
}

export default App;
